import React from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
//import states from '../../../statics/states_germany.json'
import { useNavigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';



//Desktop Race Menu
export function RaceMenuDesktop(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    const breeds = props.breeds

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function navigateAndClose(path){
        setAnchorEl(null)
        navigate(path)
    }    

    return (
        <div>
            <Button
                sx={props.sx}
                aria-owns={anchorEl ? "state-menu" : undefined}
                aria-haspopup="true"
                onMouseOver={isMobile?null:handleClick}
                onClick={isMobile?handleClick:null}
            >
                Rassen ▾
            </Button>
            <Menu
                id="state-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}>

                {breeds.map((breed) => (
                    <MenuItem key={breed.breed.toString()} onClick={()=>navigateAndClose('Rasse/' + breed.breed)}>{breed.breed}</MenuItem>

                ))}

            </Menu>
        </div>
    );
}

