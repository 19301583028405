import { Paper, Box, Typography, Container, Link, AppBar } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import React from 'react'

export default function Footer() {

    const firstRow = ['Impressum', 'Über', 'Kontakt', 'Datenschutz']

    return (
        <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }} st={100}>

            <Box>
                <Paper>
                    <Container maxWidth="xl">
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', }}>


                            {firstRow.map((item) => (


                                <Link key={item} variant='subtitle1' sx={{ margin: '10px' }} color='inherit' to={'/' + item} underline='hover' component={RouterLink} title={item}>
                                    {item}
                                </Link>
                                
                                ))}



                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex' }, justifyContent: 'center', }}>

                            <Typography variant="caption" sx={{ margin: '10px' }}>
                                © 2023 PocketPet
                            </Typography>
                        </Box>

                    </Container>
                </Paper>
            </Box>


        </AppBar>
    )
}


