import React, { useContext } from 'react';
import { AppBar, Toolbar, Typography, Container, Link } from '@mui/material'
import PetsIcon from '@mui/icons-material/Pets';
import { Link as RouterLink } from 'react-router-dom';
import MobileMenu from './mobile-menu';
import DesktopMenu from './desktop-menu'

import { GlobalDataContext } from '../../hooks/DataContext';



function ResponsiveAppBar ()  {

  const breeds = useContext(GlobalDataContext)


  return (
    <AppBar position="static" sx={{ mb: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <DesktopLogo />
          <DesktopMenu breeds={breeds} />

          <MobileMenu breeds={breeds} />
          <MobileLogo/>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;



function DesktopLogo() {

  return (
    <>
      {/**Menu that only appears in hogher resolutions*/}

      <Link underline='none' component={RouterLink} to='/' color="inherit" title='Zur PocketPet Startseite'>
        <PetsIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
      </Link>


      <Link underline='none' component={RouterLink} to='/' color="inherit" title='Zur PocketPet Startseite'>
        <Typography
          variant="h6"
          noWrap
          component="div"
          
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            fontWeight: 700,
            letterSpacing: '.3rem',
            color: 'inherit',
            textDecoration: 'none',
          }}>
          PocketPet
        </Typography>
      </Link>

    </>
  )

}


function MobileLogo() {

  return (
    <>
      <Link underline='none' component={RouterLink} to='/' color="inherit" title='Zur PocketPet Startseite'>

        <PetsIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />

      </Link>

      <Typography
        variant="h5"
        noWrap
        component="div"
        

        sx={{
          mr: 2,
          display: { xs: 'flex', md: 'none' },
          flexGrow: 1,
          fontFamily: 'monospace',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        PocketPet
      </Typography>
    </>
  )

}
