import React from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import states from '../../../statics/states_germany.json'
import { useNavigate } from 'react-router-dom';
import {isMobile} from 'react-device-detect';



//Desktop State Menu
export function StateMenuDesktop(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate();

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function navigateAndClose(path){
        setAnchorEl(null)
        navigate(path)
    }


    return (
        <div>
            <Button
                sx={props.sx}
                aria-owns={anchorEl ? "state-menu" : undefined}
                aria-haspopup="true"
                onMouseOver={isMobile?null:handleClick}
                onClick={isMobile?handleClick:null}

            >
                Bundesland ▾
            </Button>
            <Menu
                id="state-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ onMouseLeave: handleClose }}>

                {states.map((state) => (
                    <MenuItem key={state.toString()} onClick={()=>navigateAndClose('Bundesland/' + state)}>{state}</MenuItem>

                ))}

            </Menu>
        </div>
    );
}


//Mobile State Menu
export function StateMenuMobile() {
    return (
        <div>StateMenu</div>
    )
}

