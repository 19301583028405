import { createContext, useState, useEffect} from 'react';
import axios from 'axios';
import {backend} from '../statics/system_variables';

export const GlobalDataContext = createContext();


export function GlobalDataProvider({ children }) {
  const [data, setData] = useState([{'breed': 'Rassen werden geladen'}]);

  useEffect(() => {
    var api_link = backend + '/available_breeds'
    axios.get(api_link)
      .then(({ data }) => {
        data.sort(function(a, b) {return b.anzahl - a.anzahl})
        setData(data)
      })
  }, [])


  return (
    <GlobalDataContext.Provider value={data}>
      {children}
    </GlobalDataContext.Provider>
  );
}