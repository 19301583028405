import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline } from '@mui/material'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import "typeface-roboto";
import { GlobalDataProvider } from './hooks/DataContext';


//preloaded sites
import ResponsiveAppBar from './components/header/header';
import Footer from './components/footer/footer';
import CookieConsentBanner from './components/formality/cookie';
//import RacePage from './components/RacePage';

//load as you go sites
const AnimalMain = React.lazy(() => import('./components/SearchPage'));
const AboutPage = React.lazy(() => import('./components/AboutPage'));
const Impressum = React.lazy(() => import('./components/formality/impressum'));
const Datenschutz = React.lazy(() => import('./components/formality/datenschutz'));
const RaceLandingPage = React.lazy(() => import('./components/RaceLandingPage'));
const RacePage = React.lazy(() => import('./components/RacePage'));
const StatePage = React.lazy(() => import('./components/StatePage'));
const StartPage = React.lazy(() => import('./components/StartPage'));

const ArticlePage = React.lazy(() => import('./components/Article'));
const ArticleLandingPage = React.lazy(() => import('./components/ArticleLandingPage'));
const AnimalPage = React.lazy(() => import('./components/AnimalPage'));


var darkTheme = createTheme({
  palette:
  {
    mode: 'light',
    primary: {
      main: '#305f56',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#fff3e7',
    },
  },
  typography:
  {
    fontFamily:
      ['Roboto', 'sans-serif'].join(','),
    fontWeightLight: 400,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          //background: '#fffaf5', // modify the background color for the Paper component
        },
      },
    },
  },
});

darkTheme = responsiveFontSizes(darkTheme)

const App = () => {


  return (
    <HelmetProvider>
      <ThemeProvider theme={darkTheme}>
        <CookieConsentBanner />

        <CssBaseline />
        <BrowserRouter basename=''>
          <GlobalDataProvider>
            <ResponsiveAppBar />
            <Routes>

              <Route path="/" element={<React.Suspense fallback={<h1>Laden ...</h1>}><StartPage /></React.Suspense>} />
              <Route path="/Suche" element={<React.Suspense fallback={<h1>Laden ...</h1>}><AnimalMain /></React.Suspense>} />
              <Route path="/Rasse" element={<React.Suspense fallback={<h1>Laden ...</h1>}><RaceLandingPage /></React.Suspense>} />
              <Route path="/Rasse/*" element={<React.Suspense fallback={<h1>Laden ...</h1>}><RacePage /></React.Suspense>} />
              <Route path="/Bundesland/*" element={<React.Suspense fallback={<h1>Laden ...</h1>}><StatePage /></React.Suspense>} />
              <Route path="/Hund/*" element={<React.Suspense fallback={<h1>Laden ...</h1>}><AnimalPage /></React.Suspense>} />
              <Route path="/Artikel/*" element={<React.Suspense fallback={<h1>Laden ...</h1>}><ArticlePage /></React.Suspense>} />
              <Route path="/Artikel" element={<React.Suspense fallback={<h1>Laden ...</h1>}><ArticleLandingPage /></React.Suspense>} />

              <Route path='/Impressum' element={<React.Suspense fallback={<h1>Laden ...</h1>}><Impressum /></React.Suspense>} />
              <Route path='/Über' element={<React.Suspense fallback={<h1>Laden ...</h1>}><AboutPage /></React.Suspense>} />
              <Route path='/Kontakt' element={<React.Suspense fallback={<h1>Laden ...</h1>}><Impressum /></React.Suspense>} />
              <Route path='/Datenschutz' element={<React.Suspense fallback={<h1>Laden ...</h1>}><Datenschutz /></React.Suspense>} />

            </Routes>
            <Footer />
          </GlobalDataProvider>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  )

}

export default App;


