import * as React from 'react';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Divider, SwipeableDrawer, Collapse } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import states from '../../statics/states_germany.json'


export default function MobileMenu(props) {


    const navigate = useNavigate();

    const [sideBarState, setSideBarState] = React.useState(false);
    const [openStateList, setOpenStateList] = React.useState(false);
    const [openBreedList, setOpenBreedList] = React.useState(false);

    const handleStateClick = () => {
        setOpenStateList(!openStateList);
    };

    const handleBreedClick = () => {
        setOpenBreedList(!openBreedList);
    };


    const toggleDrawer = (open) => (event) => {

        setSideBarState(open)
    };

    function closeAndNavigate(path) {
        setSideBarState(false)
        setOpenStateList(false)
        setOpenBreedList(false)
        navigate('/' + path)
    }


    function ListItemState() {
        return (
            <>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleStateClick}>
                        <ListItemText primary="Bundesland" />
                        {openStateList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>

                <Collapse in={openStateList} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {states.map((state) => (
                            <ListItem disablePadding key={state.toString()}>

                                <ListItemButton sx={{ pl: 4 }} key={state} onClick={() => closeAndNavigate('Bundesland/' + state)}>
                                    <ListItemText primary={state} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </>
        )
    }


    function ListItemBreed() {
        return (
            <>
                <ListItem disablePadding>
                    <ListItemButton onClick={handleBreedClick}>
                        <ListItemText primary="Rassen" />
                        {openBreedList ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </ListItem>

                <Collapse in={openBreedList} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {props.breeds.map((breed) => (
                            <ListItem disablePadding key={breed.breed.toString()}>

                                <ListItemButton sx={{ pl: 4 }} key={breed.breed} onClick={() => closeAndNavigate('Rasse/' + breed.breed)}>
                                    <ListItemText primary={breed.breed} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            </>
        )
    }



    const list = (
        <Box sx={{ width: 250 }} role="presentation">

            <List>
                <ListItem disablePadding>

                    <ListItemButton onClick={() => closeAndNavigate('')}>
                        <ListItemText primary='Startseite' />
                    </ListItemButton>
                </ListItem>

                <ListItem disablePadding>
                    <ListItemButton onClick={() => closeAndNavigate('Suche')}>
                        <ListItemText primary='Suche' />
                    </ListItemButton>
                </ListItem>

                <ListItemBreed />
                <ListItemState />

                <ListItem disablePadding>
                    <ListItemButton onClick={() => closeAndNavigate('Artikel')}>
                        <ListItemText primary='Artikel' />
                    </ListItemButton>
                </ListItem>
                

            </List>
            <Divider />
        </Box>)



    return (
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

            <React.Fragment key='left'>
                <IconButton
                    size="large"
                    aria-label="Menü öffnen"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer(true)}
                    color="inherit"
                >
                    <MenuIcon />
                </IconButton>


                <SwipeableDrawer
                    anchor='left'
                    open={sideBarState}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    {list}
                </SwipeableDrawer>
            </React.Fragment>

        </Box>
    );
};


