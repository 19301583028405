import CookieConsent, {Cookies} from "react-cookie-consent";
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useTheme } from '@mui/material/styles';


export default function CookieConsentBanner(){

    const [cookie, setCookie] = useState(false)
    
    //checks for cookie consent at the beginning
    useEffect(() => {
        if (Cookies.get('CookieConsent') === 'true') {
            setCookie(true)
        }
    }, [])

    function googleTagJavaScript() {
        return "window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }gtag('js', new Date());gtag('config', 'G-99VFTKGLS0');"
    }


    return (
        <>
        <CookieConsent 
                debug={false} 
                buttonText="Einverstanden." 
                declineButtonText = "Lieber nicht."
                enableDeclineButton = {true}
                buttonStyle={{ backgroundColor: useTheme().palette.primary.light, fontSize: "20px" }}
                declineButtonStyle={{ fontSize: "20px" }}
                onAccept={() => {setCookie(true)}}
                >
                    Auf dieser Seite werden Cookies verwendet und Nutzerdaten erhoben. Deine IP wird dabei an Google Analytics übertragen. Falls du das nicht möchtest, kannst du die Cookies ablehnen.
                </CookieConsent>   

        {cookie? 
        <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-99VFTKGLS0"></script>
            <script>{googleTagJavaScript()}</script>
        </Helmet>
        : null}
        </>
    );
}

